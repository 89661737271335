<template>
  <b-row
      class="m-1"
  >
    <b-col md="12">
      <div class="d-flex align-items-center mt-1">
          <div class="user-page-info">
            <h6 class="mb-0" :class="badWord(track.title) ? 'text-danger' : (!track.file ? 'text-warning': '')">
              {{ track.title }}
            </h6>
            <small v-if="track.play_count" class="text-muted">{{ track.play_count }}</small>
          </div>
        <span class="ml-auto">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              v-if="track && !track.file"
              @click="downloadTrack(track.id)"
          >
            <feather-icon icon="DownloadIcon"/>
          </b-button>
          <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              :disabled="true"
          >
            <feather-icon icon="FileIcon"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              @click="deleteTrack(track.id)"
          >
            <feather-icon icon="Trash2Icon"/>
          </b-button>
        </span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCol,
  BLink,
  BRow,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { badWord } from '@core/utils/filter'
import 'animate.css';

export default {
  components: {
    BAvatar,
    BLink,
    BRow,
    BCol,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
  setup(props, { emit }) {
    const downloadTrack = (id) => {
      emit('block')
      store.dispatch('artist/downloadTrack', {id : id})
          .then(response => {
            emit('refresh')
          })
    }

    const deleteTrack = (id) => {
      emit('block')
      store.dispatch('artists/deleteTrack', {id : id})
          .then(() => {
            emit('refresh')
          })
    }

    return {
      downloadTrack,
      deleteTrack,
      badWord,
    }
  },
}
</script>

<style scoped>

</style>