<template>
  <content-with-sidebar
      class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper" v-if="artistDetail">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
              _img-top
              _img-alt="Постер"
              _:title="`${artistDetail.title}`"
          >
            <h1 class="d-flex">{{ artistDetail.title }}
              <div class="d-flex ml-auto">
                <span class="font-weight-bold font-large-2 ml-1">
                  <div class="small">{{ artistDetail.popularity }}</div>
                </span>
              </div>
            </h1>

            <b-row>
              <b-col
                  cols="12"
                  md="12"
                  class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <b-card
                    title="Исполнитель"
                    class="col-md-12 col-12"
                >
                  <b-overlay
                      :show="isLoadingArtist"
                      rounded="sm"
                  >
                    <b-row>
                      <b-col md="12">

                        <div class="mb-1">
                          <b-button variant="primary" class="mr-1"
                                    @click="loadLastfmInfo"
                          >
                            <feather-icon
                                icon="TvIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">LastFM</span>
                          </b-button>
                          <b-button
                              @click="ignoreTracks"
                          >
                            <feather-icon
                                icon="UsersIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Игнорировать пропущенное</span>
                          </b-button>
                        </div>

                        <b-tabs
                            pills
                        >
                          <!-- Tab: Песни (таблица) -->
                          <b-tab>
                            <template #title>
                              <feather-icon
                                  icon="Share2Icon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Песни [{{ artistDetail.tracks.length}}]</span>
                            </template>

                            <div class="my-1 py-25">
                              <b-table
                                  ref="refTracksListTable"
                                  :items="artistDetail.tracks"
                                  responsive
                                  :fields="tableColumns"
                                  :tbody-tr-class="rowClass"
                                  primary-key="id"
                                  show-empty
                                  empty-text="No tracks"
                                  class="position-relative"
                              >

                                <!-- Column: Client -->
                                <template #cell(artist)="data">
                                  <span class="text-nowrap">
                                    {{ data.item.artist }}
                                  </span>
                                </template>

                                <!-- Column: Client -->
                                <template #cell(play_count)="data">
                                  <span class="text-nowrap">
                                    {{ formatNumber(data.item.play_count) }}
                                  </span>
                                </template>

                                <!-- Column: Client -->
                                <template #cell(actions)="data">
                                  <span class="text-nowrap">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="warning"
                                        class="btn-icon ml-1"
                                        :to="{name: 'track-search', params: { id: data.item.id }}"
                                    >
                                    <feather-icon icon="DownloadIcon"/>
                                    </b-button>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        :variant="data.item.file ? `success` : ``"
                                        :disabled="!data.item.file"
                                        class="btn-icon ml-1"
                                        :href="data.item.url"
                                    >
                                      <feather-icon :icon="data.item.file ? `SaveIcon` : `FileIcon`"/>
                                    </b-button>
                                    <b-button
                                        :variant="data.item.ignore ? 'danger' : 'primary'"
                                        class="btn-icon ml-1"
                                        @click="toggleIgnore(data.item)"
                                    >
                                      <feather-icon :icon="(data.item.ignore) ? 'EyeOffIcon' : 'EyeIcon'"/>
                                    </b-button>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="danger"
                                        class="btn-icon ml-1"
                                        @click="deleteTrack(data.item.id)"
                                    >
                                      <feather-icon icon="Trash2Icon"/>
                                    </b-button>
                                  </span>

                                </template>
                              </b-table>

                              <b-button variant="primary" class="mr-1"
                                        @click="loadAdditionalTracks"
                              >
                                <feather-icon
                                    icon="PlusSquareIcon"
                                    class="mr-50"
                                />
                                <span class="align-middle">Дополнительные треки</span>
                              </b-button>

                              <div v-if="additional">
                                <div v-for="track in additional" class="d-flex flex-md-row flex-column mt-1">
                                  <b-button
                                      variant="warning"
                                      class="btn-icon mr-1"
                                      size="sm"
                                      @click="addTrack(track.title)"
                                  >
                                    <feather-icon icon="PlusIcon"/>
                                  </b-button>

                                  <span class="font-weight-bold d-block text-nowrap">
                                    <span class="text-success">
                                      {{ track.artist }} - {{ track.title }}
                                    </span>
                                  </span>

                                </div>
                              </div>

                            </div>
                          </b-tab>


                          <!-- Tab: Списки -->
                          <b-tab>
                            <template #title>
                              <feather-icon
                                  icon="Share2Icon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Списки</span>
                            </template>

                            <div class="my-1 py-25">
                              <b-row
                                  class="m-1"
                                  v-for="list in artistDetail.lists"
                                  :key="list.id"
                              >
                                <b-col md="12">
                                  <div class="d-flex align-items-center mt-0">
                                    <div class="user-page-info">
                                      <h6 class="mb-0">
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="21"
                                        />
                                        {{ list.title }}
                                      </h6>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>

            <div
                class="blog-content"
                v-html="artistDetail.description"
            />

          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
    >
      <!-- Похожие артисты -->
      <div class="blog-recent-posts mt-3" v-if="similar">
        <h6 class="section-label mb-75">
          Похожие артисты
        </h6>
        <b-media
            v-for="(artist, index) in similar"
            :key="artist.mbid"
            no-body
            :class="index? 'mt-2':''"
        >
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                <feather-icon
                    icon="CompassIcon"
                    class="mr-25"
                />
                {{ artist.name }}
              </b-link>
            </h6>

            <div class="text-nowrap">
              <b-button
                  v-if="!artist.db"
                  variant="success"
                  class="btn-icon"
                  size="sm"
                  @click="addArtist(artist)"
                  :disabled="artist.loading"
              >
                <feather-icon icon="UserPlusIcon"/>
              </b-button>
              <b-button
                  v-else
                  :to="{name: 'artist-view', params: { id: artist.db.id }}"
                  variant="primary"
                  class="btn-icon"
                  size="sm"
              >
                <feather-icon icon="ArrowRightCircleIcon"/>
              </b-button>
              <b-button
                  :href="artist.url"
                  variant="warning"
                  class="btn-icon ml-1"
                  size="sm"
              >
                <feather-icon icon="CastIcon"/>
              </b-button>
            </div>
          </b-media-body>
        </b-media>
      </div>
      <!--/ Похожие артисты -->
    </div>

  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {badWord, formatNumber, formatDate} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from "@/router";
import store from "@/store";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import TrackRow from "@/views/db-music/TrackRow";
import {count} from "echarts/src/component/dataZoom/history";

export default {
  components: {
    TrackRow,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BSpinner,
    BOverlay,
    BTable,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },
  methods: {
    count,
    rowClass(item, type) {
      const colorClass = 'table-danger'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (badWord(item.title)) { return colorClass }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'artists'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const artistDetail = ref(null)
    const similar = ref([])
    const additional = ref([])
    const isLoadingArtist = ref(false)
    const isLoadingStaff = ref(false)
    const disabledArtistId = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'id', label: '#', sortable: false },
      { key: 'artist', sortable: true },
      { key: 'track', sortable: true },
      { key: 'play_count', sortable: true },
      { key: 'actions' },
    ]

    const fetchArtistData = () => {
      let id = router.currentRoute.params.id
      store.dispatch('artists/fetchArtistDetails', {
        id,
        queryParams:
            {
              with_tracks: 1,
              with_lists: 1,
            }
      }).then(res => {
        artistDetail.value = res.data.data
      })
    }

    const addArtist = (artist) => {
      artist.loading = true
      blockData()
      store.dispatch(`artists/createArtist`, {title: artist.name}).
      then(response => {
        fetchSimilar()
        unblockData()
      })
    }

    const deleteTrack = (id) => {
      blockData()
      store.dispatch('artists/deleteTrack', {id : id})
          .then(() => {
            refreshData()
          })
    }

    const loadLastfmInfo = () => {
      let id = router.currentRoute.params.id
      blockData()
      store.dispatch('artists/loadLastfmInfo', {id : id})
          .then(() => {
            refreshData()
          })
    }

    const loadAdditionalTracks = () => {
      let id = router.currentRoute.params.id
      blockData()
      store.dispatch('artists/additionalTracks', {id : id})
          .then(response => {
            additional.value = response.data
            unblockData()
          })
    }

    const addTrack = (title) => {
      let id = router.currentRoute.params.id
      blockData()
      store.dispatch('artists/addTrack', {id : id, queryParams: {title: title}})
          .then(response => {
            refreshData()
          })
    }

    const toggleIgnore = (track) => {
      blockData()
      store.dispatch('artists/updateTrack', {id : track.id, queryParams: {ignore: !track.ignore}})
          .then(() => {
            refreshData()
          })
    }

    const downloadTrack = (id) => {
      blockData()
      store.dispatch('artist/downloadTrack', {id : id})
          .then(response => {
            refreshData()
          })
    }

    const fetchSimilar = () => {
      let id = router.currentRoute.params.id
      store.dispatch('artists/fetchSimilarArtists', {id})
          .then(response => {
            similar.value = response.data
          })
    }

    const refreshData = () => {
      blockData()
      fetchArtistData()
      unblockData()
    }

    const blockData = () => {
      isLoadingArtist.value = true
    }

    const unblockData = () => {
      isLoadingArtist.value = false
    }

    fetchArtistData()
    fetchSimilar()

    const ignoreTracks = () => {
      blockData()
      store.dispatch('artists/ignoreTracks', {
        id: router.currentRoute.params.id
      }).then(response => {
        fetchArtistData()
        unblockData()
      })
    }

    return {
      ignoreTracks,
      formatDate,
      artistDetail,
      similar,
      additional,
      isLoadingArtist,
      isLoadingStaff,
      disabledArtistId,
      refreshData,
      addArtist,
      blockData,
      unblockData,
      tableColumns,
      badWord,
      formatNumber,
      deleteTrack,
      loadAdditionalTracks,
      loadLastfmInfo,
      addTrack,
      toggleIgnore,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
